import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Select, { AsyncSelect } from '@atlaskit/select';
import TextField from '@atlaskit/textfield';
import PropTypes from 'prop-types';
import PresenceUnavailableIcon from '@atlaskit/icon/glyph/presence-unavailable';
import Button from '@atlaskit/button/new';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import { useStore } from '../store';
import If from './If';

const LinkTests = observer(({ onClose }) => {
  const store = useStore();
  const { jira, user } = store;
  const [matchedTests, setMatchedTests] = useState([]);
  const [matchedTestsMeta, setMatchedTestsMeta] = useState({});
  const [searchTxt, setSearchTxt] = useState('');
  const [searchTqlInputValue, setSearchTqlInputValue] = useState('');
  const [loadedSavedTqls, setLoadedSavedTqls] = useState([]);
  const [searchTestsRequestId, setSearchTestsRequestId] = useState(1);
  const [chosenLogic, setChosenLogic] = useState('AND');
  const [linkedTests, setLinkedTests] = useState([]);
  const [unlinkedTests, setUnlinkedTests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showSavedTqlPanel, setShowSavedTqlPanel] = useState(false);
  const [tags, setTags] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const [filterTql, setFilterTql] = useState('');
  // const [projectLinkedTests, setProjectLinkedTests] = useState([]);
  const fetchedSuites = useCallback(async (inputValue) => {
    const { data } = await jira.testomatioRequest(`/suites?file_type=file&query=${inputValue}`, {
      method: 'GET',
    });
    if (!data) return;
    const suiteItems = await data.map((suiteData) => {
      return { label: suiteData.attributes['public-title'], value: suiteData.id };
    });
    //fetch tags
    const resTags = await jira.testomatioRequest('/tags', {
      method: 'GET',
    });
    setTags(resTags.data);
    return suiteItems;
  }, [jira]);

  const morePlans = async (num) => {
    let items = [];
    // eslint-disable-next-line
    for (let i = 2; i <= num; i++) {
      // eslint-disable-next-line
      const planList = await jira.testomatioRequest(`/plans?page=${i}`, {
        method: 'GET',
      });
      if (planList.data) {
        items = [...items, ...planList.data];
      }
    }
    return items.map((plan) => {
      return { label: plan.attributes.title, value: plan.id };
    });
  };

  const fetchedPlans = useCallback(async () => {
    let items = [];
    const planList = await jira.testomatioRequest('/plans', {
      method: 'GET',
    });
    if (planList.data) {
      items = planList.data.map((plan) => {
        return { label: plan.attributes.title, value: plan.id };
      });
    }
    if (planList.meta.total_pages > 1) {
      const more = await morePlans(planList.meta.total_pages);
      items = [...items, ...more];
    }
    setPlans(items);
    setLoader(false);
    // eslint-disable-next-line
  }, [plans]);

  useEffect(() => {
    fetchedPlans();
    // eslint-disable-next-line
  }, []);

  const searchTql = useCallback((filterVals) => {
    let tqlQueries = {};
    if (filterVals.searchTxt) {
      filterVals.searchTxt.split(' ').map(x => x.trim()).forEach(word => {
        if (word.startsWith('@')) {
          if (word.length > 1) {
            if (!tqlQueries.tags) tqlQueries.tags = [];
            tqlQueries.tags.push(word.substring(1));
          }
        } else {
          if (word.length) {
            if (!tqlQueries.search) tqlQueries.search = [];
            tqlQueries.search.push(word);
          }
        }
      });
    }

    let result = [];
    if (tqlQueries.tags) result.push('tag IN [' + tqlQueries.tags.map(x => tqlQuote(x)).join(',') + ']');
    if (tqlQueries.search && tqlQueries.search.length) result.push('(' + tqlQueries.search.map(x => '(test % ' + tqlQuote(x) + ')').join(' AND ') + ')');
    if (filterVals.suite && filterVals.suite.length) result.push('suite IN [' + tqlQuote(filterVals.suite) + ']');
    if (filterVals.plan && filterVals.plan.length) result.push('plan IN [' + tqlQuote(filterVals.plan) + ']');
    const logic = filterVals.logic && filterVals.logic.length ? filterVals.logic : 'and'

    return result.join(` ${logic} `);
  }, []);

  const tqlQuote = (str) => {
    return `'${str.replace(/'/g, "\\'")}'`;
  };

  const resetMatchedTestsMeta = () => {
    setMatchedTests([]);
    setMatchedTestsMeta({ total_pages: 0, num: 0 });
  };

  const loadSavedTestsTqls = useCallback(async () => {
    const response = await jira.testomatioRequest(`/queries?has_chart=false&context=tests`, { method: 'GET' });
    const savedTqls = response.data.map(x => { return { id: x.id, ...x.attributes } });
    setLoadedSavedTqls(savedTqls);
  }, [jira, setLoadedSavedTqls]);

  const searchTestsByTql = useCallback(async (filterVals_in) => {
    console.log('filterVals_in', filterVals_in);
    const rId = searchTestsRequestId + 1;
    setSearchTestsRequestId(rId);
    resetMatchedTestsMeta();
    setLoading(true);
    const filterVals = filterVals_in || filterValues;
    const tql = (showAdvanced) ? filterVals_in?.tql || searchTqlInputValue : searchTql(filterVals);
    setFilterTql(tql);
    const response = await jira.testomatioRequest(`/tests?detail=1&rId=${rId}&tql=${encodeURIComponent(tql)}`, { method: 'GET', asText: true });
    const data = JSON.parse(response || '{}');
    if (data.error) {
      jira.toast = { type: 'error', message: `Search error: ${data.error}` };
    } else {
      if (data.meta && parseInt(data.meta.rId || 0) === rId) {
        const foundTests = (data.data || []).map((item) => {
          return { id: item.id, ...item.attributes };
        });
        setMatchedTests(foundTests);
        setMatchedTestsMeta(data.meta);
      }
    }
    setLoading(false);
  }, [jira, searchTql, setFilterTql, searchTqlInputValue, showAdvanced, filterValues, searchTestsRequestId]);

  const selectSuite = useCallback(async (suiteId) => {
    setFilterValues((prevValues) => {
      const updatedValues = { ...prevValues, suite: suiteId };
      searchTestsByTql(updatedValues);
      return updatedValues;
    });
  }, [searchTestsByTql]);

  const searchText = useCallback(async () => {
    setFilterValues((prevValues) => {
      const updatedValues = { ...prevValues, searchTxt };
      searchTestsByTql(updatedValues);
      return updatedValues;
    });
  }, [searchTxt, searchTestsByTql]);

  const selectPlan = useCallback(async (plan) => {
    setFilterValues((prevValues) => {
      const updatedValues = { ...prevValues, plan };
      searchTestsByTql(updatedValues);
      return updatedValues;
    });
  }, [searchTestsByTql]);

  useEffect(() => {
    const testIds = jira
      .itemsFromJiraProperties(jira.associatedItems)
      .filter(test => test.slug === jira.testomatioProject.slug && test.type === 'tests' && !test.branch && !!test.id)
      .map(test => test.id);
    const linked = matchedTests.filter(test => testIds.includes(test.id));
    const unlinked = matchedTests.filter(test => !testIds.includes(test.id));

    setLinkedTests(linked);
    setUnlinkedTests(unlinked);
  }, [loading, jira.jiraId, jira, matchedTests]);

  const linkAllTests = useCallback(async () => {
    if (!user.isLoggedIn) return;
    setLoading(true);
    // eslint-disable-next-line
    for (let i = 0; i < unlinkedTests.length; i++) {
      // eslint-disable-next-line
      await jira.testomatioRequest('/jira/issues', {
        method: 'POST',
        body: JSON.stringify({ jira_id: jira.jiraId, test_id: unlinkedTests[i].id }),
      });
    }
    await jira.getAssociatedTests(jira.jiraId);
    setLoading(false);
  }, [unlinkedTests, jira, user.isLoggedIn]);

  const unlinkAllTests = useCallback(async () => {
    if (!user.isLoggedIn) return;
    setLoading(true);
    const tests = linkedTests.map(item => item.id);
    try {
      await jira.testomatioRequest(`/jira/issues/${jira.jiraId}`, {
        method: 'DELETE',
        prefix: true,
        body: JSON.stringify({ test_ids: tests }),
      });
    } catch (e) {
      //
    } finally {
      await jira.getAssociatedTests(jira.jiraId);
      setLoading(false);
    }
  }, [jira, linkedTests, user.isLoggedIn]);

  const clickEnter = (e) => {
    if (e.key === 'Enter') {
      searchText(searchTxt);
    }
  };

  const toggleChosenLogic = useCallback(async () => {
    const logic = (chosenLogic === 'AND') ? 'OR' : 'AND'
    setChosenLogic(logic);
    setFilterValues((prevValues) => {
      const updatedValues = { ...prevValues, logic };
      searchTestsByTql(updatedValues);
      return updatedValues;
    });
  }, [chosenLogic, searchTestsByTql]);

  const CustomFooter = () => {
    return (
      <ModalFooter>
        <If condition={showAdvanced}>
          <Button
            size="small"
            appearance="link"
            spacing="compact"
            onClick={() => setShowSavedTqlPanel(!showSavedTqlPanel)}
          >
            {showSavedTqlPanel ? 'Close' : ''}
            {' '}
            Saved Queries {showSavedTqlPanel ? '' : `(${loadedSavedTqls.length})`}
          </Button>
        </If>
          
            <Button
              size="small"
              appearance="link"
              spacing="compact"
              onClick={() => {
                resetMatchedTestsMeta();
                setSearchTxt('');

                if (!showAdvanced) {
                  setSearchTqlInputValue(filterTql);
                  loadSavedTestsTqls();
                } else {
                  setSearchTqlInputValue('');
                  setFilterTql('');
                }
                setShowAdvanced(!showAdvanced);
              }}
            >
              {showAdvanced ? 'Close advanced' : 'Advanced'}
              {' '}
              filter
            </Button>
          
          <If condition={unlinkedTests.length > 0}>
            <Button
              size="small"
              appearance="primary"
              sizes="small"
              spacing="compact"
              onClick={() => linkAllTests()}
              isLoading={loading}
            >
              Link All
            </Button>
          </If>
          <If condition={linkedTests.length > 0}>
            <Button
              size="small"
              appearance="default"
              sizes="small"
              spacing="compact"
              onClick={() => unlinkAllTests()}
              isLoading={loading}
            >
              Unlink All
            </Button>
          </If>

      </ModalFooter>
    );
  };

  return (
    <Modal
      onClose={onClose}
      scrollBehavior="inside-wide"
      height={600}
      width={1200}
    >
      <ModalHeader>
        <If condition={!loader}>
        <If condition={showAdvanced}>
              <div className="w-full flex items-center space-x-1">
                <TextField
                  name="tql"
                  label="Tql"
                  placeholder="Insert search Tests tql and press Search button"
                  className="w-full"
                  value={searchTqlInputValue}
                onChange={(e) => setSearchTqlInputValue(e.target.value)}
                />
                <Button
                  appearance="primary"
                  onClick={() => searchTestsByTql()}
                  isLoading={loading}
                >
                Search
              </Button>
            </div>
        </If>
        
          <If condition={!showAdvanced}>
          <div className="w-full flex justify-center items-center space-x-1">
            <AsyncSelect
              className="single-select w-full"
              onChange={(item) => {
                return selectSuite(item ? item.value : null);
              }}
              isClearable
              cacheOptions
              defaultOptions
              loadOptions={fetchedSuites}
              placeholder="Search a suite"
              maxMenuHeight={220}
            />
            <span className="text-sm cursor-pointer cp" onClick={() => toggleChosenLogic()}>{chosenLogic}</span>
            <Select
              className="single-select w-full"
              classNamePrefix="react-select"
              options={plans}
              placeholder="Search a plan"
              onChange={(item) => {
                return selectPlan(item ? item.value : null);
              }}
              isClearable
              maxMenuHeight={220}
            />
            <span className="text-sm cursor-pointer cp" onClick={() => toggleChosenLogic()}>{chosenLogic}</span>
            <TextField
              name="title"
              label="Title"
              placeholder="Type @tag and press 'Enter'"
              className="link-test-textfield"
              value={searchTxt}
              list="title-list"
              onChange={(e) => setSearchTxt(e.target.value)}
              onKeyPress={(e) => clickEnter(e)}
            />
            <datalist id="title-list">
              {(tags || []).map(tag => (
                <option value={`@${tag.id}`} />
              ))}
            </datalist>
        </div>

          </If>
          
        </If>
      </ModalHeader>
      <ModalBody>
      <If condition={!loader}>
        <div className="w-full flex justify-between items-center mb-1">
            <div>
              <If condition={!showAdvanced && !showSavedTqlPanel}>
                <If condition={filterTql.length > 0}>
                  <span className="text-xs text-blue-600">Query:&nbsp;</span>
                  <span className="text-xs">{filterTql}</span>
                </If>
              </If>
            </div>
          <If condition={!loading && !showSavedTqlPanel}>
            <span className="flex text-xs">
              Found: {matchedTestsMeta.num || 0} tests
            </span>
          </If>
        </div>
        <If condition={showSavedTqlPanel && showAdvanced && !loading}>
            <ul className="w-full">
              {loadedSavedTqls.map((tqlQuery) => (
                <TqlQueriesListItem item={tqlQuery} onChoose={(tql) => {
                  setSearchTqlInputValue(tql.query || '');
                  searchTestsByTql({ tql: tql.query || '' });
                  setShowSavedTqlPanel(false);
                }} />
              ))}
            </ul>
        </If>
      <If condition={matchedTests.length > 0 && !loading && !showSavedTqlPanel}>
        {matchedTests.map((test) => (
          <TestItem test={test} isLinked={(linkedTests || []).map(t => t.id).includes(test.id)} />
        ))}
      </If>
      <If condition={matchedTests.length === 0 && !loading && !showSavedTqlPanel}>
        <div className="w-full flex justify-center mt-24">No results found</div>
      </If>
      <If condition={loading}>
        <div className="flex justify-center mt-24">
          <Spinner size="large" />
        </div>
      </If>
    </If>
      </ModalBody>
      <CustomFooter />
    </Modal>
  );
});


const TqlQueriesListItem = observer(({ item, onChoose }) => {
  const insertToInput = useCallback(() => {
    onChoose(item);
  }, [item, onChoose]);

  return (
    <li className="p-0 space-x-4 flex justify-between w-full">
      <div className="flex space-x-2 truncate">
        <span className="mr-1 font-mono text-xs text-blue-600 cursor-pointer hover:underline whitespace-nowrap cp" onClick={() => insertToInput()}>
          {item.title}
        </span>
        <div className="font-mono text-xs whitespace-nowrap truncate">
          {item.query}
        </div>
      </div>
    </li>
  );
});

const TestItem = observer(({ test, isLinked }) => {
  const [linked, setLinked] = useState(null);
  const [loading, setLoading] = useState(false);
  const { jira } = useStore();

  const linkTest = useCallback(async () => {
    setLoading(true);
    const body = {
      jira_id: jira.jiraId,
      test_id: test.id,
    };

    const response = await jira.testomatioRequest('/jira/issues', {
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (response) setLinked(true);
    setLoading(false);
  }, [jira, test]);

  const unlinkTest = useCallback(async () => {
    setLoading(true);
    const response = await jira.testomatioRequest(
      `/jira/issues/${jira.jiraId}`,
      { method: 'DELETE', body: JSON.stringify({ test_id: test.id }) },
    );
    if (response) setLinked(false);
    setLoading(false);
  }, [jira, test]);

  useEffect(() => {
    if (!test) return;
    setLinked(isLinked);
  }, [test, isLinked]);

  return (
    <div className="flex justify space-x-2 items-center mb-1">
      <PresenceUnavailableIcon size="small" />
      <span className="pr-1">
        {test['is-branched'] && test.branch && (
          <span
            className="mr-2 inline-block align-middle truncate bg-indigo-700 text-white text-xs px-2"
            style={{ maxWidth: '60px' }}
          >
            {test.branch}
          </span>
        )}

        {test.title}
      </span>
      {' '}
      <If condition={linked}>
        <Button
          size="small"
          appearance="default"
          sizes="small"
          spacing="compact"
          className="ml-2"
          onClick={() => unlinkTest()}
          isLoading={loading}
        >
          Unlink
        </Button>
      </If>
      <If condition={!linked}>
        <Button
          size="small"
          appearance="primary"
          sizes="small"
          spacing="compact"
          className="ml-2"
          onClick={() => linkTest()}
          isLoading={loading}
        >
          Link
        </Button>
      </If>
    </div>
  );
});

TestItem.propTypes = {
  test: PropTypes.any.isRequired,
  jiraId: PropTypes.string.isRequired,
  testomatioURL: PropTypes.string.isRequired,
};

export default LinkTests;
